import React from "react"
import Layout from "../../components/Layout"
import ContactUsComponent from "../../components/ContactUs"
import SEO from "../../components/seo"

export default function ContactUs() {
  return (
    <Layout headerType="education">
      <SEO title="Compulease · Contact Us Education" />
      <ContactUsComponent sector="Education" />
    </Layout>
  )
}
